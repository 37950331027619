import Head from 'next/head'

const FourOhFour = () => {
  return (
    <div>
      <Head>
        <title>Not Found | Sunsett</title>
      </Head>
      <h1>404 - Not found</h1>
    </div>
  )
}

export default FourOhFour
